import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { ContentCard, Modal } from '@molecules';
import { useLocale, useTranslate, useMediaQuery } from '@hooks';
import { breakpoints } from '@src/utils';
import { common, talkToUs } from '@microcopies';
import { get } from '@utils/http';
import { Shimmer } from '@atoms';
import { ContactInfo } from '@content/models/contactInfo';

const TalkToUsModal = ({
  open,
  onClose,
  id = 'modalDialog'
}: {
  open: boolean;
  onClose: () => void;
  id?: string;
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const isTablet = useMediaQuery(breakpoints.tablet);
  const commonTranslate = useTranslate(common, (x) => x.common);
  const talkToUsTranslate = useTranslate(talkToUs, (x) => x.talkToUs);

  const locale = useLocale();

  const { data: contactInfo, error: contactInfoError } = useSWR(
    open ? `/api/content/contactInfo/?locale=${locale}` : null,
    get<ContactInfo>()
  );

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const isLoading = !contactInfo && !contactInfoError;

  const callContent = contactInfo ? (
    <div>
      {contactInfo?.phoneNumbers?.map(({ label, countryCode, phoneNumber }) => (
        <div className="flex gap-1" key={label}>
          <p className="text-light-black">{label}:</p>
          <a
            className="text-base font-normal text-black"
            href={`tel:${countryCode ?? ''} ${phoneNumber}`}
          >
            {countryCode} {phoneNumber}
          </a>
        </div>
      ))}

      <div className="flex items-center gap-1 mt-4">
        <p className="text-light-black">
          {talkToUsTranslate((x) => x.reservationsHours)}:
        </p>

        {Boolean(contactInfo?.phoneNumbers?.length) && (
          <p>{contactInfo?.phoneNumbers[0].availability}</p>
        )}
      </div>
    </div>
  ) : null;

  const emailContent = contactInfo ? (
    <div className="flex flex-col gap-4">
      {contactInfo?.emails?.map(({ label, email }) => (
        <div key={label} className="flex flex-col">
          <p className="text-xs text-light-black">{label}</p>
          <a
            className="text-base font-normal text-black"
            href={`mailto:${email}`}
          >
            {email}
          </a>
        </div>
      ))}
      {contactInfo?.externalLinks?.map(({ url, urlText, label }) => (
        <div key={label} className="flex flex-col">
          <p className="text-xs text-light-black">{label}</p>
          <a className="text-base font-normal text-black" href={url}>
            {urlText}
          </a>
        </div>
      ))}
    </div>
  ) : null;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      hasTopBorder={false}
      hasTopPadding={false}
      isCentered={isTablet}
      isFullScreenWithSpaceAround={!isTablet}
    >
      <div className="flex w-full px-10">
        <h2 id={`${id}-title`} className="mb-3" style={{ flexGrow: 1 }}>
          {commonTranslate((x) => x.talkToUs)}
        </h2>
      </div>

      <div className="flex flex-col p-10 pt-0">
        <p id={`${id}-description`} className="mb-8 text-black body-text-1">
          {talkToUsTranslate((x) => x.subtitle)}
        </p>

        <div className="flex flex-col gap-3 text-black">
          <Shimmer
            isShimmering={isLoading}
            className="flex-col w-full rounded-11xl"
          >
            <ContentCard
              className="!rounded-5xl"
              onClick={() => {}}
              backgroundColor="bg-warm-gray-2"
              title={talkToUsTranslate((x) => x.call)}
              description={callContent}
            />
          </Shimmer>
          <Shimmer
            isShimmering={isLoading}
            className="flex-col w-full rounded-11xl"
          >
            <ContentCard
              className="!rounded-5xl"
              backgroundColor="bg-snow-blue"
              onClick={() => {}}
              title={talkToUsTranslate((x) => x.email)}
              description={emailContent}
            />
          </Shimmer>
        </div>

        {!contactInfo && contactInfoError && (
          <div className="flex items-center justify-center w-full">
            <p>{commonTranslate((x) => x.somethingWentWrong)}</p>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TalkToUsModal;

import React, { useState } from 'react';
import clsx from 'clsx';
import NextLink from 'next/link';
import { Button, Icon } from '@hurtigruten/design-system-components';

import { ContentfulImage, IconBullet } from '@atoms';
import { useLocale, useOnLoad } from '@hooks';
import { isOverflowing } from '@utils';
import ContentfulXRAY from '@components/contentful/ContentfulXray';

const ContentCard = ({
  id,
  contentType,
  title,
  subtitle,
  description,
  titleIcon,
  titleIconBackgroundColor = 'bg-warm-gray-2',
  titleImageUrl,
  isTitleSmall = false,
  isCTA = false,
  onClick,
  buttonHref,
  buttonText,
  hasArrowButton,
  isArrowSkew,
  className,
  backgroundColor = 'bg-white',
  descriptionMaxHeight,
  externalHref
}: {
  id?: string;
  contentType?: string;
  title: string;
  subtitle?: string | React.ReactNode;
  description?: string | React.ReactNode;
  titleIcon?: TIconType;
  titleIconBackgroundColor?: string;
  titleImageUrl?: string;
  isTitleSmall?: boolean;
  isCTA?: boolean;
  onClick?: () => void;
  buttonHref?: string;
  buttonText?: string;
  backgroundColor?: string;
  hasArrowButton?: boolean;
  isArrowSkew?: boolean;
  className?: string;
  descriptionMaxHeight?: number;
  externalHref?: string;
}) => {
  const [isDescriptionOverflowing, setIsDescriptionOverflowing] =
    useState(false);
  const [descriptionElement, setDescriptionElement] =
    useState<HTMLElement | null>(null);

  const locale = useLocale();

  const updateIsDescriptionOverflowing = () => {
    setIsDescriptionOverflowing(isOverflowing(descriptionElement) ?? false);
  };

  useOnLoad(
    descriptionMaxHeight ? updateIsDescriptionOverflowing : undefined,
    descriptionMaxHeight ? updateIsDescriptionOverflowing : undefined,
    [descriptionElement]
  );

  const shouldBeExpandable =
    (descriptionMaxHeight && isDescriptionOverflowing) || !descriptionMaxHeight;

  const shouldShowFakeButton =
    (hasArrowButton && shouldBeExpandable && isCTA) ||
    (buttonText && !buttonHref && !onClick);

  const shouldShowRealArrowButton =
    hasArrowButton && shouldBeExpandable && !isCTA && !shouldShowFakeButton;

  const card = (
    <ContentfulXRAY title={title} contentType={contentType} entryID={id}>
      <div
        data-testid="contentCard-container"
        className={clsx(
          'p-6 text-left flex flex-col gap-2 max-h-fit',
          backgroundColor,
          {
            'hover:cursor-pointer': onClick,
            'flex-1': isCTA,
            [className || '']: className
          }
        )}
        onClick={shouldBeExpandable ? onClick : undefined}
      >
        <div>
          <div className="flex items-center gap-3 mb-4">
            {titleIcon && (
              <div data-testid="contentCard-titleIcon">
                <IconBullet
                  icon={titleIcon}
                  iconSize="xl"
                  iconColorClassName="text-black"
                  backgroundColorClassName={titleIconBackgroundColor}
                />
              </div>
            )}
            {titleImageUrl && (
              <ContentfulImage
                data-testid="contentCard-titleImage"
                src={titleImageUrl}
                width={40}
                height={40}
                className="rounded-full"
              />
            )}
            <p
              className={clsx('font-medium', {
                'h5-text': !isTitleSmall,
                'text-base leading-4': isTitleSmall
              })}
              data-testid="contentCard-title"
            >
              {title}
            </p>
          </div>
          {subtitle && (
            <div
              data-testid="contentCard-subtitle"
              className="text-light-black"
            >
              {subtitle}
            </div>
          )}
        </div>

        {description && typeof description === 'string' && (
          <p
            className={clsx({
              'relative overflow-hidden': descriptionMaxHeight,
              'fade-out-warm-gray-1': isDescriptionOverflowing
            })}
            style={{ maxHeight: descriptionMaxHeight }}
            ref={(ref) => setDescriptionElement(ref)}
            data-testid="contentCard-description"
          >
            {description}
          </p>
        )}
        {description && typeof description !== 'string' && (
          <div
            className={clsx({
              'relative overflow-hidden': descriptionMaxHeight,
              'fade-out-warm-gray-1': isDescriptionOverflowing
            })}
            style={{ maxHeight: descriptionMaxHeight }}
            ref={(ref) => setDescriptionElement(ref)}
            data-testid="contentCard-description"
          >
            {description}
          </div>
        )}
        {buttonText && !hasArrowButton && (
          <div className="self-end">
            <Button
              testId="contentCard-button"
              appearance="primary"
              size="small"
              href={buttonHref}
              text={buttonText}
            />
          </div>
        )}
        {shouldShowRealArrowButton && (
          <div className="self-end">
            <Button
              testId="contentCard-arrowButton"
              appearance="link"
              icon={isArrowSkew ? 'arrow-right-up' : 'arrow-right'}
              href={buttonHref}
              text={buttonText}
            />
          </div>
        )}
        {shouldShowFakeButton && (
          <div className="flex items-center self-end gap-2 mt-auto">
            <p className="ui-text">{buttonText}</p>
            <Icon graphic={isArrowSkew ? 'arrow-right-up' : 'arrow-right'} />
          </div>
        )}
      </div>
    </ContentfulXRAY>
  );

  if (isCTA && buttonHref) {
    return (
      <NextLink
        href={buttonHref}
        locale={locale}
        className="flex flex-col w-full gap-2 font-normal outline-none active:ring-2 focus-within:ring-2 ring-penguin-yellow ring-offset-2 content-card"
      >
        {card}
      </NextLink>
    );
  }

  if (isCTA && externalHref) {
    return (
      <a
        href={externalHref}
        target="_blank"
        rel="noopener noreferrer"
        className="flex flex-col w-full gap-2 font-normal outline-none active:ring-2 focus-within:ring-2 ring-penguin-yellow ring-offset-2 content-card"
      >
        {card}
      </a>
    );
  }

  return card;
};

export default ContentCard;

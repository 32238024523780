import { useRouter } from 'next/router';

import { getSharedEnvironmentVariables } from '@src/utils/environmentVariables';

import useLocale from './useLocale';

export const useSeoWebpageMarkup = (title: string, description: string) => {
  const router = useRouter();
  const locale = useLocale();
  const sharedEnvironmentVariables = getSharedEnvironmentVariables();

  return {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': `${title}`,
    abstract: `${sharedEnvironmentVariables.WEBSITE_URL ?? '/'}${locale}${
      router?.asPath ?? ''
    }`,
    description: `${description}`
  };
};
